import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import BannerEnMantenimiento from '../../../assets/images/banner_en_mantenimiento.png';
import './Mantenimiento.scss';

const Mantenimiento = ({ message }) => {

    const style = {
        'background': "url(" +BannerEnMantenimiento +")" 
    }

    return (
        <>
            <div className="app-mantenimiento" style={style}>
                <h1>Página en Mantenimiento</h1>
                <div className="app-mantenimiento__description">{ReactHtmlParser(message)}</div>
            </div>
        </>
    )
}

export default Mantenimiento