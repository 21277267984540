import React , {useEffect, useState} from "react";
import { getUser } from "../../services/Storage.Utils";
import * as Api from "../../api/index";

const Avatar = () => {

  const [avatar,setAvatar] = useState(null);

  useEffect(() => {
    
    const getAvatar = async () => {

      const response = await Api.GetAvatar(getUser().rut);
      setAvatar(response.avatar);

  }

  getAvatar();

  }, [])
  
  return (
    <img
      src={avatar}
      className="rounded-circle img-fluid"
      alt=""
    />
  );
};

export default Avatar;
