import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Alert from '../../../components/Alert/Alert';
import { setUserLogin } from '../../../services/Storage.Utils';
import * as Api from "../../../api";

const FormLogin = () => {
    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState('');
    const [loader, setLoader] = useState(false);
    const [inputs, setInputs] = useState({
        //rut: "17524659-2",
        //password: "735982",
        rut: "",
        password: "",
    });
    const handleLogin = async () => {
        const { rut, password } = inputs;
        if (rut === '') {
            setMsg('Debe ingresar un Rut');
            setStatus('danger');
            return false;
        }
        if (password === '') {
            setMsg('Debe ingresar Password');
            setStatus('danger');
            return false;
        }
        setLoader(true);
        const response = await Api.SingIn(inputs)
        const { status, validations, result } = response;
        if (status === 'success') {
            console.log(response);
            console.log(result);
            setUserLogin(result.api_token, result.client);
            window.location.href = '/mi-sacramental/resumen';
        } else {
            setMsg(validations.result.msg);
            setStatus(validations.status)
        }
        setLoader(false);
    };
    const handleInput = (e) => {
        let valor = e.target.value;
        if (e.target.name === "rut") {
          valor = valor
            .replace(/[.-]/g, "")
            .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
         
        }
    
        setInputs({
          ...inputs,
          [e.target.name]: valor,
        });
        // setInputs({
        //     ...inputs,
        //     [e.target.name]: e.target.value,
        // });
    };

    return (

        <form>
            <div className="content-form">
                <div className="form-group">
                    <label>RUT</label>
                    <input type="text" id="rut"  name="rut" className="text-center form-control" onChange={handleInput} placeholder="12.345.678-8" value={inputs.rut} />
                </div>
                <div className="form-group">
                    <label>CLAVE</label>
                    <input type="password" id="password"  name="password" className="text-center form-control" onChange={handleInput} placeholder="*******" />
                </div>
                <Alert msg={msg} status={status} />
            </div>
            <div className="form-group">
                <Button
                    classes={'bt-ms _bt-green'}
                    handleLogin={handleLogin}
                    loader={loader}
                    name={'Ingresar'}
                />
            </div>
            <div className="form-group row">
                <div className="col-7 text-left">
                    <Link to='/recover' className='recover tx _black'>¿Olvidó su contraseña?</Link>
                </div>
                <div className="col text-right">
                    <Link to='/register' className="recover tx _black">Registrarse</Link>
                </div>
            </div>
        </form>

    )
}
export default FormLogin;