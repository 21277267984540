import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getToken } from './mi-sacramental/services/Storage.Utils';
import routesPrivate from './mi-sacramental/Page/routesPrivate';
import Login from './mi-sacramental/Page/Login/Login/Login';
import Recover from './mi-sacramental/Page/Recover/Recover';
import Register from './mi-sacramental/Page/Register/Register';
import DetailPago from './components/DetailPago/DetailPago';
import NotFound from './pages/404/NotFound';
import AccesoFirmante from './pages/Acceso-Firmante';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getToken();
  if (token !== null) {
    return <Route component={() => <Component {...rest} />} />;
  }
  return <Redirect to={'/mi-sacramental/'} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const renderContent = (props) => {
  const Path = props?.match?.path;
  // console.log(Path);
  return getToken() != null ? (
    <BrowserRouter>
      <PrivateRoute path={`${Path}/`} component={routesPrivate} />
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route
          path={`/mi-sacramental/detail/:origen/:buy_order/:token/:status/:code`}
          component={DetailPago}
        />
        <Route exact path={`/`} component={Login} />
        <Route exact path={`/register`} component={Register} />
        <Route exact path={`/recover`} component={Recover} />
        <Route exact path={`/acceso-firmante`} component={AccesoFirmante} />
        {/* <Route component={NotFound} /> */}
        <Redirect to={`/`} />
      </Switch>
    </BrowserRouter>
  );
};

export default renderContent;
