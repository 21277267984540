import React, { Component } from "react";
import LogoSendero from "./../assets/images/mi-sacramental/misacramental.png";

import { Link } from "react-router-dom";
class Logo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { add_class } = this.props;
    return (
      <div className={"logo " + add_class}>
        <Link to={"/"}>
          <img src={LogoSendero} alt="Mi Sacramental" height="125" /> 
        </Link>
      </div>
    );
  }
}

export default Logo;
