import React, { useEffect, useState } from 'react';
import "./../../../assets/scss/main.scss";
import Footer from "../../../components/Footer/Footer";
import MegaMenu from "../../../../components/Header";
import Mantenimiento from "../../../components/Mantenimiento/Mantenimiento";
import Spinner from "../../../components/Spinner/Spinner";
import { GetMantenimiento } from '../../../api';

const Layout = ({ bg, classes, children }) => {

  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

 

  useEffect(() => {

    const getIsMantenimiento = async () => {

      const data = await GetMantenimiento();
      
      setTimeout(() => {
        if (typeof data !== 'undefined') {
          setLoading(false);
          setDisabled(data.data.mantenimiento);
          setMessage(data.data.message);
        }
      }, 1000);
    }

    getIsMantenimiento();

  }, []);

  return (
    <>
      {
        loading ?
          <div className="text-center"><Spinner></Spinner></div> :
          disabled ? <Mantenimiento message={message} />
            :
            <>
              <div id="outer-container">
                <MegaMenu />

                <p className="text-center"></p>
                <main className={bg}>
                  <div className={`${classes} box-white`}>
                    <div className="container">
                      <div className="row">{children}</div>
                    </div>
                  </div>
                </main>
                <footer>
                  <Footer />
                </footer>
              </div>
            </>
      }
    </>
  );
};

export default Layout;
