import React from 'react';
import { getContrato, getUser } from '../../../services/Storage.Utils';
import TitleSection from '../../TitleSection/TitleSection';
import ItemAgreement from './Item';

const BoxAgreement = ({ contrato, all_medida_class }) => {
  const Agreement = getContrato();
  return (
    <div className={all_medida_class}>
      {typeof contrato !== 'undefined' ? (
        [Agreement[contrato]].map((item, index) => (
          <ItemAgreement {...item} key={index} hidden_button={true} />
        ))
      ) : (
        <>
          <TitleSection
            title={'Resumen'}
            subtitle={`Hola ${
              getUser().nombre
            }, acá está el resumen de tus productos contratados.`}
            st_medida_class="d-none d-md-block"
          />
          <div className="row">
            {Agreement &&
              Agreement !== null &&
              Object.keys(Agreement).map((i) =>
                [Agreement[i]].map((item, index) => (
                  <div className="col-12 col-md-6">
                    <ItemAgreement
                      {...item}
                      key={index}
                      type={typeof Agreement[i].pie}
                      hidden_button={false}
                    />
                  </div>
                ))
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default BoxAgreement;
