import axios from 'axios';

const linkApi = process.env.REACT_APP_API_URL_VOYAGER;
//const linkApi = 'http://127.0.0.1:8000';

const encodeQueryString = (filters) => {
    let url = '';
    Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
            filters[key].map((item) => {
                if (item !== null) {
                    url += `${key}=${item}&`;
                }
                return true;
            });
        } else if (filters[key]) {
            url += `${key}=${filters[key]}&`;
        }
    });
    return url.substring(0, url.length - 1);
}

const hookup = async (method, url, data) => {
    let mainResponse = {
        data: [],
        validations: null
    };
    const query = encodeQueryString(data);
    url = method === 'get' ? `${url}?${query}` : url;
    let config = {
        method: method,
        baseURL: linkApi,
        url: url,
        data: data
    }
    mainResponse = await axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.info(
                `Ups! ocurrio un error toma un capture y reportalo: \n\n[${method}] ${linkApi}${url}\n\n${error}`
            );
            const response = error.response
            if (response.status === 400 || response.status === 422) {
                mainResponse.validations = response.data;
                return mainResponse;
            }
            return mainResponse
        });
    return mainResponse
}
export default hookup;