import React from 'react';
import Title from '../../../components/Title/BoxText';
import Layout from '../Layout/Layout';
import FormLogin from './Form';
import './Login.scss';

const Login = () => {
    return (
        <Layout classes={'ct-public'} bg={'bg-fluid-bottom'}>
            <div className="row justify-content-center w-100">
                <div className="col-md-8 bg-mobile">
                    <div className="ct-public__form text-center">
                        <Title title1={'Ingresa a'} title2={'Mi Sacramental'} subtitle={'Completa tus datos para continuar'} />
                        <FormLogin />
                    </div>
                </div>
            </div>
            {/* <Posts /> */}
        </Layout>
    )
}
export default Login;