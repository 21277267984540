import React, { useEffect, useState } from 'react';
import Layout from '../Cliente/Layout/Layout';
import { useLocation } from 'react-router-dom';
import { useFormState } from '../../hooks/useForm';
import TablePie from '../../components/TabServicios/TablePie';
import { getContrato, getUser } from '../../services/Storage.Utils';
import ButtonPay from '../../components/Pay/ButtonPay';
import TitleSection from '../../components/TitleSection/TitleSection';

var selectedCuota = {};
const PagoPie = () => {
  const Agreement = getContrato();
  const { state } = useLocation();
  const id = typeof state?.id !== 'undefined' ? state.id : '';
  const [tagreement, settAgreement] = useState({
    Agreement: getContrato(),
    amounts: [],
  });
  const [disabledPagar, setDisabledPagar] = useState(false);
  const calculaMontoTotal = () => {
    var valor = 0;
    Object.keys(selectedCuota).map(
      (item) => (valor += parseInt(selectedCuota[item].MONTO_CUOTA))
    );
    setDisabledPagar(valor > 0 ? false : true);

    //setMontoTotal(valor);
  };
  const onClick = (e, items) => {
    var objeto = {};
    if (e) {
      [items].forEach((todo) => {
        objeto = {
          NUMERO_CONTRATO: todo.NUMERO_CONTRATO,
          NUMERO_CUOTA: todo.NUMERO_CUOTA,
          CONCEPTO_RECAUDACION: todo.CONCEPTO_RECAUDACION,
          MONTO_CUOTA: parseInt(todo.MONTO_CUOTA),
          NUMERO_DOCUMENTO: todo.NUMERO_DOCUMENTO,
          CUOTA_MOROSA: todo.CUOTA_MOROSA,
          CODIGO_COMERCIO: todo.CODIGO_COMERCIO,
        };

        selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ] = objeto;
        setCuotaMontoSeleccionadas(todo.NUMERO_CONTRATO, objeto, objeto);
      });
    } else {
      [items].forEach((todo) => {
        delete selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ];
      });
    }
  };

  //   const [formValues] = useFormState({ selectedCuotaPay: selectedCuota });
  //   const { selectedCuotaPay } = formValues;
  //   var cuotasSeleccionadas = JSON.stringify(selectedCuotaPay);
  const validate = (v) => {
    if (v === 'undefined') {
      return false;
    }
    return true;
  };
  function viewBuy() {
    let b_return = false;
    Object.keys(Agreement).map((i) => {
      [Agreement[i]].map((item) => {
        b_return = typeof item.pie === 'object' ? true : false;
      });
    });
    return b_return;
  }

  useEffect(() => {
    let keys = [];
    getContrato() &&
      getContrato() !== null &&
      Object.keys(getContrato()).map((i) => {
        keys.push({
          amount: 0,
          contrato: getContrato()[i].contrato,
          cuotasSeleccionadas: null,
        });
      });

    settAgreement({ Agreement: getContrato(), amounts: keys });
    //console.log(iamount);
  }, []);

  const getAmountByAgreemnt = (amounts, contrato) => {
    let result =
      tagreement &&
      tagreement.amounts &&
      tagreement.amounts.find((c) => {
        return c.contrato === contrato;
      });

    return result?.amount ? result.amount : 0;
  };

  const getCuotasSeleccionadasByAgreemnt = (amounts, contrato) => {
    let result =
      tagreement &&
      tagreement.amounts &&
      tagreement.amounts.find((c) => {
        return c.contrato === contrato;
      });

    return result?.cuotasSeleccionadas
      ? JSON.stringify(result.cuotasSeleccionadas)
      : {};
  };

  const setCuotaMontoSeleccionadas = (contrato, cuotas, obj) => {
    let result = [];
    tagreement.amounts.map((k) => {
      if (k.contrato === contrato) {
        result.push({
          amount: parseInt(obj.MONTO_CUOTA),
          contrato: k.contrato,
          cuotasSeleccionadas: [obj],
        });
      } else {
        result.push(k);
      }
    });
    settAgreement({ Agreement: getContrato(), amounts: result });
    console.log(tagreement.amounts);
  };

  return (
    // <Layout bg={'bg-fluid-bottom'}>
    //   <div className="container-cartola-pie">
    //     {viewBuy() ? (
    //       <>
    //         {id > 0
    //           ? [Agreement[id]].map(
    //               (item) =>
    //                 typeof item.pie === 'object' && (
    //                   <div className="container-cartola-pie__box">
    //                     <TitleSection
    //                       title={`Contrato ${item.producto} ${item.contrato}`}
    //                       subtitle={`Bienvenido ${getUser().nombre}`}
    //                       all_medida_class={''}
    //                       st_medida_class={''}
    //                     />
    //                     <TablePie
    //                       {...item}
    //                       title={item.titulo}
    //                       text={'Total Pago Pie Sacramental'}
    //                       id={'table-gray'}
    //                       type={'P'}
    //                       onClick={onClick}
    //                       calculaMontoTotal={calculaMontoTotal}
    //                     />
    //                   </div>
    //                 )
    //             )
    //           : Object.keys(Agreement).map((i) =>
    //               [Agreement[i]].map(
    //                 (item) =>
    //                   typeof item.pie === 'object' && (
    //                     <div className="container-cartola-pie__box">
    //                       <TitleSection
    //                         title={`Contrato ${item.producto} ${item.contrato}`}
    //                         subtitle={`Bienvenido ${getUser().nombre}`}
    //                         all_medida_class={''}
    //                         st_medida_class={''}
    //                       />
    //                       <TablePie
    //                         {...item}
    //                         title={item.titulo}
    //                         text={'Total Pago Pie Sacramental'}
    //                         id={'table-gray'}
    //                         type={'P'}
    //                         onClick={onClick}
    //                         calculaMontoTotal={calculaMontoTotal}
    //                       />
    //                     </div>
    //                   )
    //               )
    //             )}
    //         <ButtonPay
    //           amount={amount}
    //           disabledPagar={disabledPagar}
    //           cuotasSeleccionadas={cuotasSeleccionadas}
    //           nameBtPay={'Pagar pie contrato'}
    //           nameBtCartola={'Descargar PDF'}
    //         />
    //       </>
    //     ) : (
    //       <h2>No contiene pago Pie disponibles</h2>
    //     )}
    //   </div>
    // </Layout>

    <>
      <Layout bg={'bg-fluid-bottom'}>
        <div className="container-cartola-pie">
          {tagreement?.Agreement &&
            tagreement?.Agreement !== null &&
            Object.keys(tagreement.Agreement).map((i) =>
              [tagreement.Agreement[i]].map((item, index) => (
                <div
                  key={'t_agreenmt_' + index}
                  className="container-cartola-pie__box"
                >
                  <TitleSection
                    title={`Contrato ${item.producto} ${item.contrato}`}
                    // subtitle={`Bienvenido ${getUser().nombre}`}
                    all_medida_class={''}
                    st_medida_class={''}
                  />
                  <TablePie
                    {...item}
                    title={item.titulo}
                    text={'Total Pago Pie Sacramental'}
                    id={'table-gray'}
                    type={'P'}
                    onClick={onClick}
                    calculaMontoTotal={calculaMontoTotal}
                  />
                  {item?.pie?.pie?.vigente?.detalle?.CTAS_MNT_SALDO > 0 && (
                    <>
                      <ButtonPay
                        amount={getAmountByAgreemnt(tagreement, item.contrato)}
                        disabledPagar={false}
                        cuotasSeleccionadas={getCuotasSeleccionadasByAgreemnt(
                          tagreement,
                          item.contrato
                        )}
                        nameBtPay={'Pagar pie contrato ' + item.contrato}
                        nameBtCartola={'Descargar PDF'}
                      />
                    </>
                  )}
                </div>
              ))
            )}
        </div>
      </Layout>
    </>
  );
};

export default PagoPie;
