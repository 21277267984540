import React, { Component } from "react";
import facebook from '../assets/images/mi-sacramental/icon-facebook.svg';
import youtube from '../assets/images/mi-sacramental/icon-youtube.svg';
import instagram from '../assets/images/mi-sacramental/icon-instagram.svg';

export class RS extends Component {
    render() {
        return (
            <div className="content-redes">
                <p>SIGANOS</p>
                <div className="content-icons">
                    <a href="https://www.facebook.com/Senderoparque/" target="_blank" rel="noopener noreferrer">
                        <img className="pointer" src={facebook} alt="" />
                    </a>
                    <a href="https://www.instagram.com/senderoparque/" target="_blank" rel="noopener noreferrer">
                        <img className="pointer" src={instagram} alt="" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww" target="_blank" rel="noopener noreferrer">
                        <img className="pointer" src={youtube} alt="" />
                    </a>
                </div>
            </div>
        )
    }
}