import React, { Component } from 'react';
import desktop from "./../assets/images/icons/social-media.svg";
import mobile from "./../assets/images/icons/social-media.d6e2a21f.svg";
class Chat extends Component {
    render() {
        const { type } = this.props;
        return (
            <>
                <div className={"content-" + type}>
                    <a href="https://wa.me/56996923573?text=Hola" target="_blank" rel="noopener noreferrer" alt="whatsapp Mi Sacramental">
                        <img src={type === 'desktop' ? desktop : mobile} alt="whatsapp Mi Sacramental" />
                    </a>
                </div>
            </>
        );
    }
}

export default Chat;