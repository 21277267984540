import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Box from '../Box/Box';
import TitleSection from '../TitleSection/TitleSection';
import Adicional from './Adicional';
import './TablePago.scss';
import ButtonAcceptContrato from '../Button/ButtonAcceptContrato';
import Sesion from '../../../service/Session';

class TablePie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }
  onClick(e, detalle, valor) {
    valor = parseInt(valor);
    if (
      valor <= 0 ||
      valor > parseInt(detalle[0].CTAS_MNT_SALDO) ||
      isNaN(valor)
    ) {
      console.info(
        'El monto debe ser mayor a 1 y menor o igual a ' +
          detalle[0].CTAS_MNT_SALDO
      );
      this.setState({
        error:
          'El monto debe ser mayor a 1 y menor o igual a ' +
          detalle[0].CTAS_MNT_SALDO,
      });
      return false;
    }
    var objeto = {};
    detalle.forEach((todo) => {
      objeto = {
        NUMERO_CONTRATO: todo.NUMERO_CONTRATO,
        NUMERO_CUOTA: todo.NUMERO_CUOTA,
        CONCEPTO_RECAUDACION: todo.CONCEPTO_RECAUDACION,
        MONTO_CUOTA: parseInt(valor),
        NUMERO_DOCUMENTO: todo.NUMERO_DOCUMENTO,
        CUOTA_MOROSA: todo.CUOTA_MOROSA,
        CODIGO_COMERCIO: todo.CODIGO_COMERCIO,
      };
    });

    this.props.onClick(false, objeto);
    this.props.onClick(true, objeto);
    this.setState({
      error: '',
    });
    this.props.calculaMontoTotal();
  }

  InfContrato(contrato) {
    let result = {
      view: false,
      infcontrato: {},
      display: '',
      class: '',
    };

    Sesion.getInformacionContratos().infcontratos.map((item) => {
      if (item.CONTRATO === contrato) {
        if (item.ACEPTA_CONTRATO == 1) {
          result.view = true;
          result.infcontrato = item;
          result.display = 'Aceptar Contrato.';
        }

        if (item.ACEPTA_CONTRATO == 2) {
          result.view = false;
          result.infcontrato = item;
          result.display = 'El contrato está en proceso de aprobación.';
          result.class = 'badge badge-warning';
        }

        if (item.ACEPTA_CONTRATO == 3) {
          result.view = false;
          result.infcontrato = item;
          result.display = 'El contrato ya ha sido aceptado.';
          result.class = 'badge badge-success';
        }
      }
    });

    return result;
  }

  render() {
    const { title, parque, producto, contrato } = this.props;
    const { error } = this.state;
    let pie = this.props?.pie?.pie;
    // console.log('IMPRIMIENDO PIE');
    // console.log(pie);

    return pie?.vigente?.detalle?.CTAS_MNT_SALDO > 0 ? (
      <>
        <h2>{title}</h2>
        {[pie.vigente].map(
          (item, index) =>
            typeof pie.vigente !== 'undefined' &&
            item.detalle.CTAS_MNT_SALDO > 0 && (
              <div key={'pie_box_item_' + index}>
                <Box>
                  <Adicional
                    {...pie.vigente.adicional}
                    parque={parque}
                    producto={producto}
                    contrato={contrato}
                  />
                </Box>
                {[item.detalle].map((item2, index) => (
                  <>
                    <div className="row" key={index}>
                      <div className="col-12">
                        <TitleSection
                          title={'Pago Pie'}
                          all_medida_class={''}
                          st_medida_class={''}
                        />
                        <Box>
                          {/* <p>Fecha de Vencimiento: {item2.FECHA_VENCIMIENTO}</p>
                                                    <p>Estado de pago: {item2.CTAS_IND_ESTADO_PAGO}</p>
                                                    <p>Interés: ${new Intl.NumberFormat("de-DE").format(item2.INTERESES)}</p>
                                                    <p>Recargo: ${new Intl.NumberFormat("de-DE").format(item2.RECARGOS)}</p> */}
                          <p>
                            Ultimo pago: $
                            {new Intl.NumberFormat('de-DE').format(
                              item2.ULT_PAGO
                            )}
                          </p>
                          <p>
                            Saldo pendiente: $
                            {new Intl.NumberFormat('de-DE').format(
                              parseInt(item2.CTAS_MNT_SALDO)
                            )}
                          </p>
                        </Box>
                      </div>
                    </div>
                    <div className="amount align-baseline">
                      <div className="row">
                        <div className="col-6">
                          <p className="align-baseline">Monto a pagar</p>
                        </div>
                        <div className="col-6">
                          <input
                            type="number"
                            onBlur={(e) => {
                              this.onClick(e, [item2], e.target.value);
                            }}
                            className={
                              'form-control ' + (error !== '' ? 'error' : '')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {this.InfContrato(contrato).view === true ? (
                  <>
                    <Box>
                      <TitleSection
                        title={''}
                        subtitle={
                          'Autorizo expresamente a la empresa y a las sociedades relacionadas a ella, según los términos establecidos en el artículo 100 de la Ley 18.045 de Mercado de Valores, ello de conformidad al artículo 4° de la Ley N°19.628 sobre Protección de la Vida Privada (“Ley de Protección de Datos”), a tratar sus datos personales, que proporciona al contratar nuestros servicios, por cualquiera de los canales de comunicación o venta, tanto presenciales como remotos, para las finalidades que la ley permite. En todo momento podrá revocar su autorización, y dispondrá siempre de los derechos de aclaración, rectificación, cancelación y oposición, y todos los demás derechos que confiere la Ley N° 19.628 sobre Protección de la Vida Privada. Para ejercer estos derechos sólo deberás llamar al 6000741380. Declaro ser mayor de edad, tener plena capacidad para contratar, haber leído y estar de acuerdo con los documentos del contrato adjuntos, manifestando por este medio mi consentimiento con su suscripción en todas sus partes.'
                        }
                        all_medida_class={'text-justify txt-acuerdos_pago_pie'}
                        st_medida_class={''}
                      />
                      <Row>
                        <Col md={3} sm={12}>
                          <ButtonAcceptContrato
                            item={this.props}
                            infcontrato={this.InfContrato(contrato).infcontrato}
                          />
                        </Col>
                      </Row>
                    </Box>
                  </>
                ) : (
                  <>
                    <span className={this.InfContrato(contrato).class}>
                      {this.InfContrato(contrato).display}
                    </span>
                  </>
                )}
              </div>
            )
        )}
      </>
    ) : (
      <>
        <Box>
          <Adicional parque={parque} producto={producto} contrato={contrato} />
        </Box>
        {this.InfContrato(contrato).view === true ? (
          <>
            <Box>
              <TitleSection
                title={''}
                subtitle={
                  'Autorizo expresamente a la empresa y a las sociedades relacionadas a ella, según los términos establecidos en el artículo 100 de la Ley 18.045 de Mercado de Valores, ello de conformidad al artículo 4° de la Ley N°19.628 sobre Protección de la Vida Privada (“Ley de Protección de Datos”), a tratar sus datos personales, que proporciona al contratar nuestros servicios, por cualquiera de los canales de comunicación o venta, tanto presenciales como remotos, para las finalidades que la ley permite. En todo momento podrá revocar su autorización, y dispondrá siempre de los derechos de aclaración, rectificación, cancelación y oposición, y todos los demás derechos que confiere la Ley N° 19.628 sobre Protección de la Vida Privada. Para ejercer estos derechos sólo deberás llamar al 6000741380. Declaro ser mayor de edad, tener plena capacidad para contratar, haber leído y estar de acuerdo con los documentos del contrato adjuntos, manifestando por este medio mi consentimiento con su suscripción en todas sus partes.'
                }
                all_medida_class={'text-justify txt-acuerdos_pago_pie'}
                st_medida_class={''}
              />
              <Row>
                <Col md={3} sm={12}>
                  <ButtonAcceptContrato
                    item={this.props}
                    infcontrato={this.InfContrato(contrato).infcontrato}
                  />
                </Col>
              </Row>
            </Box>
          </>
        ) : (
          <>
            <span className={this.InfContrato(contrato).class}>
              {this.InfContrato(contrato).display}
            </span>
          </>
        )}
      </>
    );
  }
}
export default TablePie;
