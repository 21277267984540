import React, { useState } from 'react';
import { set, useForm } from 'react-hook-form';
import classes from './Form.module.scss';
import dataForm from './dataForms';
import Button from '../Button/Button';
import { getUser, setUserLogin } from '../../services/Storage.Utils';
import * as Api from '../../api';
import Validate from '../../../service/Validate';

export default function Formulario({ nombre, rut, email }) {
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const [inputs, setInputs] = useState({
    rut: localStorage.getItem('rut'),
    // nombre: localStorage.getItem('nombre'),
  });
  const handleRegister = async () => {
    setMsg('');
    setLoader(true);

    let send = inputs;
    send.rut = localStorage.getItem('rut');
    console.log(send);

    // setInputs({
    //   ...inputs,
    //   ["rut"]: localStorage.getItem('rut'),
    // });
    // console.log(inputs);
    // return false;

    // setMsg(result.msg);
    // setStatus(status);

    // console.log(inputs);
    // return false;

    if (String(inputs.password) != String(inputs.password_confirm)) {
      setMsg('Las contraseñas no coinciden.');
      setStatus('danger');
      setLoader(false);
      return false;
    }

    let rtel = Validate.isTelefonoOk(String(inputs.telefono), 'teléfono');
    if (!rtel.bresult) {
      setMsg(rtel.mresult);
      setStatus('danger');
      setLoader(false);
      return false;
    }

    let rtel2 = Validate.isTelefonoOk(String(inputs.celular), 'celular');
    if (!rtel2.bresult) {
      setMsg(rtel2.mresult);
      setStatus('danger');
      setLoader(false);
      return false;
    }

    const response = await Api.SaveRegister(send);
    // console.log(response);
    // return false;
    const { status, result } = response;
    if (status === 'success') {
      setMsg(result.msg);
      setStatus(status);
      setUserLogin(result.user.api_token, result.user);
      window.location.href = '/mi-sacramental/resumen';
    } else {
      setMsg(result.msg);
      setStatus(status);
    }
    setLoader(false);
  };
  const handleInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const ValueSet = (id) => {
    if (id === 'rut') {
      return localStorage.getItem('rut');
    }
    // if (id === "nombre") {
    //   return localStorage.getItem('nombre');
    // }
  };
  return (
    <form onSubmit={handleSubmit(handleRegister)} className={classes.form_di}>
      <div className="row">
        {dataForm &&
          dataForm['contacto'] &&
          dataForm['contacto'].map((item, index) => (
            <div className={'form-group ' + item.col} key={index}>
              {item.type_option === 'input' && (
                <input
                  {...register(item.name, {
                    ...item.check,
                  })}
                  id={item.id}
                  name={item.name}
                  placeholder={item.placeholder}
                  type={item.type}
                  className={item.clase}
                  key={index}
                  onChange={handleInput}
                  value={ValueSet(item.id)}
                  disabled={item.disabled ? true : ''}
                  maxLength={item?.maxLength ?? false}
                  onKeyPress={(e) => {
                    if (!item.keypress) {
                      return true;
                    }

                    if (item.name == 'telefono' || item.name == 'celular') {
                      if (!item.keypress.soloNumeros(e)) {
                        e.preventDefault();
                        return false;
                      }
                    }

                    return true;
                  }}
                />
              )}
              {errors[item.name] &&
                typeof errors[item.name] !== 'undefined' && (
                  <p className={classes.errors}>{errors[item.name]?.message}</p>
                )}
            </div>
          ))}
        {msg !== '' && (
          <div className="form-group col-12">
            <div className="alert alert-danger">{msg}</div>
          </div>
        )}
        <div className="col-12">
          <Button
            classes={'bt-ms _bt-green'}
            loader={loader}
            name={'Registrarse'}
            type={'submit'}
          />
        </div>
      </div>
    </form>
  );
}
