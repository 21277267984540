import isEmail from 'validator/lib/isEmail'
export default {
    contacto: [
        {
            id: 'nombre',
            name: 'nombre',
            placeholder: 'Nombre *',
            type: 'text',
            col: 'col-12 col-md-6',
            clase: 'form-control',
            disabled:false,
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
                maxLength: { value: 50, message: "Nombre demasiado largo" },
            }
        },
        {
            id: 'rut',
            name: 'rut',
            placeholder: 'Rut *',
            type: 'text',
            col: 'col-12 col-md-6',
            clase: 'form-control',
            disabled:true,
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
                maxLength: { value: 50, message: "Nombre demasiado largo" },
                validate: {},
                pattern: {
                    value: T => T,
                }
            }
        },
        {
            id: 'email',
            name: 'email',
            placeholder: 'Email *',
            type: 'email',
            col: 'col-12 col-md-12',
            clase: 'form-control',
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
                maxLength: { value: 50, message: "Nombre demasiado largo" },
                validate: {
                    isValid: (value) => isEmail(value) || 'Email no válido'
                },
            },
        },
        {
            id: 'telefono',
            name: 'telefono',
            placeholder: 'Teléfono *',
            type: 'tel',
            col: 'col-12 col-md-12 input-group',
            clase: 'form-control',
            type_option: 'input',
            maxLength: 9,
            check: {
                required: { value: true, message: 'Campo requerido' },
                minLength: { value: 9, message: 'Número de Teléfono debe tener 9 dígitos' },
                maxLength: { value: 9, message: 'Número de Teléfono debe tener 9 dígitos' },
            },
            keypress: {
                soloNumeros : (e) => { return e.charCode >= 48 && e.charCode <= 57}
            }
        },
        {
            id: 'celular',
            name: 'celular',
            placeholder: 'Celular *',
            type: 'tel',
            col: 'col-12 col-md-12 input-group',
            clase: 'form-control',
            type_option: 'input',
            maxLength: 9,
            check: {
                required: { value: true, message: 'Campo requerido' },
                minLength: { value: 9, message: 'Número de Teléfono debe tener 9 dígitos' },
                maxLength: { value: 9, message: 'Número de Teléfono debe tener 9 dígitos' },
            },
            keypress: {
                soloNumeros : (e) => { return e.charCode >= 48 && e.charCode <= 57}
            }
        },
        {
            id: 'direccion',
            name: 'direccion',
            placeholder: 'Dirección *',
            type: 'text',
            col: 'col-12 col-md-12 input-group',
            clase: 'form-control',
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
            },
        },
        {
            id: 'password',
            name: 'password',
            placeholder: 'Password *',
            type: 'password',
            col: 'col-12 col-md-12 input-group',
            clase: 'form-control',
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
            },
        },
        {
            id: 'password_confirm',
            name: 'password_confirm',
            placeholder: 'Confirmar Password *',
            type: 'password',
            col: 'col-12 col-md-12 input-group',
            clase: 'form-control',
            type_option: 'input',
            check: {
                required: { value: true, message: 'Campo requerido' },
            },
        },
    ]
}