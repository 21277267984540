import React, { Component } from 'react';
import { Container, Spinner, Collapse } from 'reactstrap';
import BannerTop from '../../components/BannerTop';
import Api from './../../service/Api';
import correcto from './../../assets/images/correcto.PNG';
import acceso from './../../assets/images/BANNER_WEB.png';
import acceso_mobile from './../../assets/images/BANNER_MOBILE.png';

import { Link } from 'react-router-dom';
import DetalleFirmanteComponent from './DetalleFirmanteComponent';

class AccesoFirmante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {},
      show: 1,
      msj: '',
      selectedContrato: {},
      loading: false,
      loading_btn: false,
      rut: '',
      nd: '',
      collapse: 1,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getPreguntas = this.getPreguntas.bind(this);
    this.verficarRespuesta = this.verficarRespuesta.bind(this);
    this.getFirmante = this.getFirmante.bind(this);
    this.confirmarFirma = this.confirmarFirma.bind(this);
    this.agregarFirma = this.agregarFirma.bind(this);
  }
  componentDidMount() {
    document.title = 'Mi Sacramental | Acceso Firmante';
    window.scrollTo(0, 0);
    this.getFirmante();
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    if (name === 'rut') {
      let valor = event.target.value;
      valor = valor
        .replace(/[.-]/g, '')
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
      this.setState({
        rut: valor,
      });
    }
    if (name === 'nd') {
      let valor = event.target.value;
      valor = valor.replace('.', '', valor);
      this.setState({
        nd: valor,
      });
    }
  }
  async getFirmante() {
    this.setState({
      loading: true,
    });
    const params = {};
    const { rut } = this.state;
    params.rut = rut;
    // params.rut = '18534831-8';

    let data = await Api.getFirmantes(params);
    if (data.response === 200) {
      this.setState({
        firmante: data.result,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  async agregarFirma(e, items) {
    var objeto = {};
    if (e) {
      items.forEach((todo) => {
        objeto = {
          FMNT_SQC: todo.FMNT_SQC,
        };
        this.state.selectedContrato[todo.FMNT_SQC + '-' + todo.CNTR_NMR] =
          objeto;
      });
    } else {
      items.forEach((todo) => {
        delete this.state.selectedContrato[todo.FMNT_SQC + '-' + todo.CNTR_NMR];
      });
    }
  }
  async confirmarFirma() {
    this.setState({
      loading_btn: true,
    });
    const params = {};
    params.selectedContrato = JSON.stringify(this.state.selectedContrato);
    let data = await Api.confirmarFirma(params);
    if (data.response === 200) {
      this.setState({
        show: 4,
        loading_btn: false,
      });
    } else {
    }
  }
  async getPreguntas() {
    const { rut, nd } = this.state;
    if (rut === '') {
      this.setState({
        msj: 'Debe ingresar RUT',
      });
      return false;
    }
    if (nd === '') {
      this.setState({
        msj: 'Debe ingresar N° Documento',
      });
      return false;
    }
    this.setState({
      loading: true,
    });
    const params = {};

    params.Rut = rut;
    params.Nd = nd;
    params.type = 'get';
    let data = await Api.Preguntas(params);
    if (data.ConsultaResult.codigoRetorno === 10000) {
      this.setState({
        preguntas: data.ConsultaResult.DETALLE.DESAFIO.PREGUNTAS,
        idChallenge: data.ConsultaResult.RESUMEN.IDCHALLENGE,
        show: 2,
        loading: false,
      });
      this.getFirmante();
    } else {
      let msj = '';
      switch (parseInt(data.ConsultaResult.codigoRetorno)) {
        case 10001:
          msj = 'Error en los parámetros de entrada 1';
          break;
        case 10005:
          msj = 'Rut no válido';
          break;
        case 10006:
          msj = 'No es posible generar el desafío';
          break;
        case 10008:
          msj = 'La cédula de identidad consultada no se encuentra vigente';
          break;
        case 10009:
          msj = 'Servicio disponible solo para personas naturales';
          break;
        case 10010:
          msj =
            'No es posible realizar una verificación de identidad mediante preguntas para este Rut';
          break;
        case 10011:
          msj =
            'No es posible generar el desafío por superar máximo de intentos permitidos';
          break;
        case 10018:
          msj =
            'Registro Civil no se encuentra disponible (si es que valida la cédula)';
          break;
        default:
          break;
      }
      this.setState({
        show: 1,
        msj: msj,
        loading: false,
      });
    }
  }
  async verficarRespuesta() {
    this.setState({
      loading: true,
    });
    const params = {};
    params.Rut = this.state.rut.replace('.', '');
    params.Nd = this.state.nd;
    params.idChallenge = this.state.idChallenge;
    params.selectedOption = JSON.stringify(this.state.selectedOption);
    params.type = 'valid';
    let data = await Api.ValidarPreguntas(params);
    if (data.ConsultaResult.codigoRetorno === 10000) {
      this.setState({
        show: 3,
        loading: false,
      });
    } else {
      let msj = '';
      switch (data.ConsultaResult.codigoRetorno) {
        case 10001:
          msj = 'Error en los parámetros de entrada';
          break;
        case 10005:
          msj = 'Rut no válido';
          break;
        case 10006:
          msj = 'No es posible generar el desafío';
          break;
        case 10007:
          msj = 'Verificación de identidad no exitosa';
          break;
        case 10012:
          msj =
            'Ha superado el tiempo límite para responder, intente nuevamente';
          break;
        default:
          break;
      }
      this.setState({
        show: 2,
        msj: msj,
        loading: false,
      });
    }
  }
  handleOptionChange(changeEvent, tipo, pregunta) {
    var objeto = {};
    objeto = {
      CODIGO_PREGUNTA: pregunta.trim(),
      CODIGO_RESPUESTA: changeEvent.target.value.trim(),
    };
    this.state.selectedOption[pregunta] = objeto;
  }

  render() {
    const { rut, nd, preguntas, firmante, show, msj, loading } = this.state;
    const PreguntasComponent = () => {
      return (
        <>
          <div className="row">
            {preguntas &&
              preguntas.map((gh, i) => (
                <div className="col-12 col-md-6" key={i}>
                  <div className="form-group">
                    <div className="container-preguntas__title">
                      {gh.PREGUNTA}
                    </div>
                    {gh.ALTERNATIVAS.map((pr, e) => (
                      <p key={e}>
                        <input
                          type="radio"
                          value={pr.CODIGO_RESPUESTA}
                          name={gh.CODIGO_PREGUNTA}
                          onChange={(ev) => {
                            this.handleOptionChange(ev, i, gh.CODIGO_PREGUNTA);
                          }}
                        />{' '}
                        {pr.RESPUESTA}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col-12 offset-md-4 col-md-4 text-center">
              <div className="form-group">
                <button
                  className="bt-ms _bt-green"
                  onClick={this.verficarRespuesta}
                >
                  SIGUIENTE
                </button>
              </div>
            </div>
          </div>
        </>
      );
    };
    const FinalizaComponent = () => {
      return (
        <>
          <div className="finaliza">
            <div className="row">
              <div className="col-12 offset-md-4 col-md-4 text-center">
                <img src={correcto} />
                <p>Estimado cliente usted ha ingresado exitosamente</p>
                <p>sus datos en el Acceso de Firmantes.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 offset-md-4 col-md-4 text-center">
                <div className="form-group">
                  <a
                    className="bt-ms _bt-green"
                    href="https://www.sacramental.cl"
                    // target="_blank"
                    rel="nofollow noopener noreferrer"
                    style={{ color: 'white' }}
                  >
                    Finalizar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    return (
      <div className="acceso-firmante">
        <Container fluid>
          <div className="d-block d-md-none">
            <BannerTop title1={''} image={acceso_mobile} />
          </div>
          <div className="d-none d-md-block">
            <BannerTop title1={''} image={acceso} />
          </div>
        </Container>
        {loading ? (
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <Spinner style={{ width: '5rem', height: '5rem' }} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            {show === 1 && (
              <div className="row">
                <div className="col-12 offset-md-4 col-md-4 text-center">
                  <div className="form-group">
                    <p className="title-form">Ingreso de Firmantes</p>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Rut"
                      name="rut"
                      onChange={this.handleInputChange}
                      value={rut || ''}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="N° Documento"
                      name="nd"
                      onChange={this.handleInputChange}
                      value={nd || ''}
                    />
                  </div>
                  {msj !== '' && (
                    <div className="form-group">
                      <div className="alert alert-danger">{msj}</div>
                    </div>
                  )}
                  <div className="form-group">
                    <button
                      className="bt-ms _bt-green"
                      onClick={this.getPreguntas}
                    >
                      INGRESAR
                    </button>
                  </div>
                </div>
              </div>
            )}
            {show === 2 && <PreguntasComponent />}
            {show === 3 && (
              <DetalleFirmanteComponent
                confirmarFirma={this.confirmarFirma}
                firmante={firmante}
                agregarFirma={this.agregarFirma}
              />
            )}
            {show === 4 && <FinalizaComponent />}
          </div>
        )}
      </div>
    );
  }
}

export default AccesoFirmante;
