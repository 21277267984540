import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  NavItem,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Collapse,
} from "reactstrap";

class ItemLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(event) {
    event.preventDefault();
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  render() {
    const {
      claseMenu,
      claseLink,
      ruta,
      classmenu,
      name,
      target,
      type,
      componnet,
      linkCurrent,
      linkFunctionCurrent,
      children,
      origen,
      handleMenuClick,
    } = this.props;
    const ArrowComponent = (params) => {
      return (
        typeof children !== "undefined" && (
          <i
            className={"arrow-black" + (this.state.collapse ? " up" : " down")}
          ></i>
        )
      );
    };
    return (
      <>
        <NavItem className={classmenu + (ruta === "parques" && "menu-parques")}>
          <>
            {ruta.includes("http") || ruta.includes("tel:") ? (
              <a href={ruta} target={target} className={claseLink}>
                {name}
                <ArrowComponent children={children} />
              </a>
            ) : (
              <>
                {typeof children === "object" && origen !== "desktop" ? (
                  <Link
                    onClick={this.toggle}
                    to={ruta}
                    className={
                      claseLink +
                      (window.location.pathname.split("/")[1] === ruta &&
                        " active")
                    }
                  >
                    {name}
                    <ArrowComponent children={children} />
                  </Link>
                ) : (
                  <Link
                    to={ruta}
                    className={
                      claseLink +
                      (window.location.pathname.split("/")[1] === ruta &&
                        " active")
                    }
                  >
                    {name}
                    <ArrowComponent children={children} />
                  </Link>
                )}
              </>
            )}
            {typeof children !== "undefined" && origen !== "desktop" && (
              <Collapse isOpen={this.state.collapse}>
                <Link onClick={handleMenuClick} to={ruta} className={claseLink}>
                  TODOS
                </Link>
                {children.map((item, index) =>
                  item.ruta.includes("http") || item.ruta.includes("tel:") ? (
                    <a
                      key={index}
                      onClick={handleMenuClick}
                      href={item.ruta}
                      target={item.target}
                      className={
                        claseLink +
                        (window.location.pathname.split("/")[1] === item.ruta &&
                          " active")
                      }
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link
                      key={index}
                      onClick={handleMenuClick}
                      to={ruta + item.ruta}
                      className={
                        claseLink +
                        (window.location.pathname.split("/")[1] === item.ruta &&
                          " active")
                      }
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </Collapse>
            )}
          </>
        </NavItem>
      </>
    );
  }
}

export default ItemLink;
