export default {
    menu: [
        {
            'name': 'Parques',
            'ruta': '/parques',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
            'children': [{
                'name': 'Sendero Arica',
                'ruta': '/arica'
            },
            {
                'name': 'Sendero Iquique',
                'ruta': '/iquique'
            },
            {
                'name': 'Sendero Villa Alemana',
                'ruta': '/villa-alemana'
            },
            {
                'name': 'Sendero Quipué',
                'ruta': '/quilpue'
            },
            {
                'name': 'Sendero Valparaíso',
                'ruta': '/valparaiso'
            },
            {
                'name': 'Sendero San Antonio',
                'ruta': '/san-antonio'
            },
            {
                'name': 'Sendero Maipú',
                'ruta': '/maipu'
            },
            {
                'name': 'Sendero San Bernardo',
                'ruta': '/san-bernardo'
            },
            {
                'name': 'Sendero Rancagua',
                'ruta': '/rancagua'
            },
            {
                'name': 'Sendero Concepción',
                'ruta': '/concepción'
            },
            {
                'name': 'Sendero Temuco',
                'ruta': '/temuco'
            }
            ]
        },
        {
            'name': 'Crematorio',
            'ruta': '/parque-crematorio',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
            'children': [
                {
                    'name': 'Planes',
                    'ruta': ''
                },
                {
                    'name': 'Ánforas',
                    'ruta': ''
                },
                {
                    'name': 'Preguntas Frecuentes',
                    'ruta': '/preguntas-frecuentes',
                    'parent': false
                },
                {
                    'name': 'Cotizar',
                    'ruta': '/cotizar',
                    'parent': false
                }
            ]
        },
        {
            'name': 'Funeraria Sendero',
            'ruta': 'https://www.funerariasendero.cl/',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
            'children': [
                {
                    'name': 'Planes',
                    'ruta': 'https://www.funerariasendero.cl/planes',
                    'parent': false
                },
                {
                    'name': 'Servicios',
                    'ruta': 'https://www.funerariasendero.cl/servicios',
                    'parent': false
                },
                {
                    'name': 'Apoyo al duelo',
                    'ruta': 'https://www.funerariasendero.cl/apoyo-al-duelo',
                    'parent': false
                },
            ]
        },
        {
            'name': 'Funerales del día',
            'ruta': '/funerales-del-dia',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Ubicación de sepulturas',
            'ruta': '/ubicacion-de-sepulturas',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Más Parque para Todos',
            'ruta': '/mas-parque-para-todos',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
            'children': [
                {
                    'name': 'Comunidad y Parque.',
                    'ruta': ''
                },
                {
                    'name': 'Retiro de Ofrenda.',
                    'ruta': ''
                },
                {
                    'name': 'Mantención del Parque.',
                    'ruta': 'tipos-de-mantenciones'
                },
                {
                    'name': 'Sustentabilidad.',
                    'ruta': ''
                }
            ]

        },
        {
            'name': 'Servicios',
            'ruta': '/servicios',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
            'children': [
                {
                    'name': 'Ceremonia de Condolencia.',
                    'ruta': '/#ceremonia-de-condolencias'
                },
                {
                    'name': 'Capilla Ecuménica.',
                    'ruta': '/#capilla-ecumenica'
                },
                {
                    'name': 'Florencia Florería.',
                    'ruta': '/#florencia-floreria'
                }
            ]
        },
        {
            'name': 'Sendero Contigo',
            'ruta': '/posts/sendero-contigo',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Noticias',
            'ruta': '/posts/noticias',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Contáctanos',
            'ruta': '/contacto',
            'classmenu': '',
            'target': '_blank',
            'estado': true,
            'type': 2,
            'children': [
                {
                    'name': 'Formulario',
                    'ruta': '',
                },
                {
                    'name': 'Whatsapp',
                    'ruta': 'https://wa.me/56937528099?text=Hola',
                    'parent': false
                },
                {
                    'name': 'Teléfono',
                    'ruta': 'tel:6007363376',
                    'parent': false
                },
            ]
        },
        {
            'name': 'Nuestras Oficinas',
            'ruta': '/oficinas',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Horarios de Parques',
            'ruta': '/horarios',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Qué hacer en caso de fallecimiento',
            'ruta': '/que-debo-hacer-en-caso-de-fallecimiento',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Mi Sendero',
            'ruta': '/mi-sendero',
            'classmenu': 'button-green',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Pago Rápido',
            'ruta': '/pago-rapido',
            'classmenu': 'button-green',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
    ]

}