import validator from 'validator';



class Validate {
    ValidateForm(value, type, texto) {
        var mensaje = "";
        switch (type) {
            case 'numerico':
                if (!validator.isNumeric(value)) {
                    mensaje = "- El " + texto + " debe tener solo caracteres válidos\n";
                }
                if (value.length !== 9) {
                    mensaje = "- El " + texto + " debe tener 9 dígitos\n";
                }
                break;
            case 'extension':
                if (!validator.isNumeric(value)) {
                    mensaje = "- El " + texto + " debe tener solo caracteres válidos\n";
                }
                if (value.length < 0 || value.length > 2) {
                    mensaje = "- El " + texto + " debe tener entre 1 a 2 dígitos\n";
                }
                break;
            case 'email':
                if (!validator.isEmail(value)) {
                    mensaje = "- El " + texto + " debe ser válido\n";
                }
                break;
            case 'rut':
                let val = value.replace(/\./g, '');
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(val)) {
                    mensaje = "- El " + texto + " debe tener solo caracteres válidos\n";
                }
                break;
            case 'texto':
                if (validator.isEmpty(value)) {
                    mensaje = "- El " + texto + " debe tener solo caracteres válidos\n";
                }
                break;
            case 'texto1':
                if (validator.isEmpty(value)) {
                    mensaje = "- El " + texto + " debe tener solo caracteres válidos\n";
                }
                break;
            case 'selector':
                if (value === 0 || value === null || value === '') {
                    mensaje = "- Debe seleccionar " + texto + "\n";
                }
                break;
            default:
                mensaje = "";
                break;
        }
        return mensaje;
    }
    validate(dataForm, validations) {
        Object.keys(dataForm).map((index) => {
            var i = dataForm[index]
            var parent = i.parentNode;
            if (typeof parent === 'object') {
                var feed = parent.getElementsByClassName('invalid-feedback');
                var search = (i.name in validations)
                i.className = i.className.replace("is-invalid", "")
                parent.className = parent.className.replace("form-check-invalid", "")
                if (feed.length > 0) {
                    feed[0].remove()
                }
                if (search) {
                    var wrapper = document.createElement('div');
                    wrapper.innerHTML = validations[i.name]
                    wrapper.className = 'invalid-feedback'
                    if (parent.classList.contains("form-check-inline")) {
                        parent.classList.add("form-check-invalid")
                    }
                    parent.appendChild(wrapper)
                    i.className = 'form-control is-invalid'
                }
            }
            return true
        })
    }

    isTelefonoOk(stelefono , nombre = 'teléfono'){

        let objResult = {
            bresult : true,
            mresult: ''
        }

        if(stelefono.trim().length <= 0){
            objResult.bresult = false;
            objResult.mresult = "Debe Ingresar un " + nombre + " válido.";
            return objResult;
        }

        if(stelefono.trim().length != 9){
            objResult.bresult = false;
            objResult.mresult = "El " + nombre + " solo puede tener 9 digitos.";
            return objResult;
        }

        if(stelefono.trim().substring(0,1) == "1" || stelefono.trim().substring(0,1) == "0" ){
            objResult.bresult = false;
            objResult.mresult = "El " + nombre + " no puedo empezar con 0 o 1.";
            return objResult;
        }

        if(stelefono.trim() == "222222222" || 
            stelefono.trim().substring(0) == "333333333" || 
            stelefono.trim().substring(0) == "444444444" || 
            stelefono.trim().substring(0) == "555555555" || 
            stelefono.trim().substring(0) == "666666666" || 
            stelefono.trim().substring(0) == "777777777" || 
            stelefono.trim().substring(0) == "888888888" || 
            stelefono.trim().substring(0) == "999999999" || 
            stelefono.trim().substring(0) == "987654321" 
            ){

            objResult.bresult = false;
            objResult.mresult = "El " + nombre + " ingresado no es válido.";
            return objResult;

        }

        return objResult;

    }

}
export default (new Validate())