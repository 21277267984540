import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import LogoSendero from '../../../assets/images/logo/LogoSendero.png';
import Icon247 from '../../../assets/images/icons/24-7.png';
import Chat from '../../Chat';
// import Auth from '../../../pago-rapido/auth';

class Footer extends Component {
  async handleMenuClick(event) {
    window.scrollTo(0, 0);
  }
  
  getYear() {
    const d = new Date();
    return d.getFullYear();
  }
  
  render() {
    return (
      <div>
        <div className="container">
          <div className="footer-container" style={{marginTop: '15px'}} >
            {/* <div className="footer-container__row" > */}
            <div className="" >
              {/* <div className="row">
                <Col md={3}>
                  <img src={LogoSendero} className="img img-logo-footer" alt="" />
                  <div className="footer-container__socials"><strong>Síganos</strong>
                    <div className="rrss">
                      <a href="https://www.facebook.com/Senderoparque/" target="_blank" rel="noopener noreferrer"><i className="icon-bottom-rrss fab fa-facebook-f"></i></a>
                      <a href="https://www.instagram.com/senderoparque/" target="_blank" rel="noopener noreferrer"><i className="icon-bottom-rrss fab fa-instagram"></i></a>
                      <a href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww" target="_blank" rel="noopener noreferrer"><i className="icon-bottom-rrss fab fa-youtube"></i> </a>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <ul className="list-footer">
                    <li className="item-footer-title">Servicios al cliente</li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/oficinas"} className="hover-link">
                        <p>Nuestras Oficinas</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/preguntas-frecuentes"} className="hover-link">
                        <p>Preguntas Frecuentes</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link smooth="true" to={"/donde-pagar/"} className="hover-link">
                        <p>Paga online</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/ubicacion-de-sepulturas"} className="hover-link">
                        <p>Ubicación de Sepultura</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/funerales-del-dia"} className="hover-link">
                        <p>Funerales Del Día</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <a href="https://www.funerariasendero.cl/apoyo-al-duelo" target="_blank" rel="noopener noreferrer" className="hover-link">
                        <p>Apoyo al duelo</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/canal-de-denuncia"} className="hover-link">
                        <p>Canal De Denuncias</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/contratos-y-reglamentos"} className="hover-link">
                        <p>Contratos y Reglamentos</p>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className="list-footer">
                    <li className="item-footer-title">Conócenos</li>
                    <li className="item-footer">
                      <a href="https://sendero.trabajando.cl/" target="_blank" rel="noopener noreferrer" className="hover-link">
                        <p>Trabaje con nosotros</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/transparencia"} className="hover-link">
                        <p>Transparencia</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/politicas-de-privacidad"} className="hover-link">
                        <p>Politica de Privacidad</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/sobre-sendero"} className="hover-link">
                        <p>Sobre Sendero</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link onClick={this.handleMenuClick} to={"/mas-parque-para-todos"} className="hover-link">
                        <p>Más parque para todos</p>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <div className="list-footer">
                    <li className="item-footer-title">Estamos aquí</li>
                  </div>
                  <p className="text-necesite">Cuando nos necesite</p>
                  <p><strong>CONTÁCTENOS LAS 24 HORAS</strong></p>
                  <div className="footer-contact">
                    <div>
                      <img src={Icon247} className="img img-247" alt="" />
                    </div>
                    <div className="">
                      <a className="footer-phone" href="tel:6007363376"><strong>600 736 3376</strong></a>
                    </div>
                  </div>
                </Col>
              </div> */}
              <hr style={{display: 'none'}} />
              <div className="row post-footer">
                <Col md={6} className="post-footer__info post-footer__info-left">
                  © { this.getYear()} Mi Sacramental
                </Col>
                <Col md={6} className="post-footer__info post-footer__info-right">
                  Todas las imágenes de este sitio son referenciales
                </Col>
              </div>
            </div>
            {/* <Chat type='desktop' /> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;