import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../services/Storage.Utils';
import MenuJson from './Menu';
import './MenuH.scss';

const LogoutSession = () => {
  logout();
  window.location.href = '/';
};
const Horizontal = () => {
  return (
    <main id="page-wrap">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="nav menu-horizontal">
          <div className="container">
            <ul className="navbar-nav mr-auto menu-items">
              {MenuJson.map((item, index) => (
                <li key={index} className="nav-item">
                  {item.name_desktop === 'Datos personales' ||
                  item.name_desktop === 'Preguntas frecuentes' ||
                  item.name_desktop === 'Contrato' ? (
                    <>
                      <Link
                        to={item.link}
                        className="nav-link"
                        onClick={(e) => {
                          localStorage.setItem('update', 0);
                        }}
                      >
                        {item.name_desktop}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={item.link}
                        className="nav-link"
                        onClick={(e) => {
                          localStorage.setItem('update', 1);
                        }}
                      >
                        {item.name_desktop}
                      </Link>
                    </>
                  )}
                </li>
              ))}
              <li className="nav-item">
                <a className="nav-link" onClick={LogoutSession}>
                  Cerrar sesión
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </main>
  );
};

export default Horizontal;
