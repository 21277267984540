import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class BannerTop extends Component {
    render() {
        const { title1, title2, image } = this.props;
        /*const sectionStyle = {
            backgroundImage: "url(" + image + ")"
        };*/
        return (
            <div>
                <Row className="banner-top">
                    <Col className="sinPadding">
                        <img src={image} style={{ width: 100 + '%' }} alt="" />
                        <h2 className="banner-top__title">
                            <span className="lato-light">{title1}</span> <strong className="lato-back">{title2}</strong>
                        </h2>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BannerTop;