import React, { Component } from "react";
import Icon247 from "./../../assets/images/icons/24-7.png";

class Phone extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type } = this.props;
    return (
      <>
        {type === "whatsapp" && (
          <a
            href="https://api.whatsapp.com/send?phone=56937528099&text=Hola%2C%20necesito más información"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="top-header-contact verde">
              <div>
                <i className="fab fa-whatsapp"></i>{" "}
              </div>
              <div className="tel">
                <p className="canal">WHATSAPP</p>
                <span className="phone">+56 9 3752 80 99</span>
              </div>
            </div>
          </a>
        )}
        {type === "callcenter" && (
          <a href="tel:6007363376">
            <div className="top-header-contact azul">
              <div>
                <img src={Icon247} className="img img-247" alt="" />
              </div>
              <div className="tel">
                <p className="canal">CALL CENTER</p>
                <span className="phone">600 736 3376</span>
              </div>
            </div>
          </a>
        )}
      </>
    );
  }
}
export default Phone;
