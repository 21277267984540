import React, { Component } from 'react';

class TableCuota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      montoTotal: 0,
      count: 0,
      ivas: process.env.REACT_APP_IVAS,
    };
    this.validadorEstado = this.validadorEstado.bind(this);
  }
  onClick(e, detalle) {
    const { checkboxes } = this.props;
    console.log(checkboxes);
    let position = 0;
    Object.keys(checkboxes).map((xs, index) => {
      if (
        detalle.NUMERO_CONTRATO +
          '-' +
          detalle.CONCEPTO_RECAUDACION +
          '-' +
          detalle.NUMERO_CUOTA ===
        xs
      ) {
        position = index;
      }
    });

    if (detalle.CONCEPTO_RECAUDACION === 'RDD') {
      var begin =
        typeof Object.keys(checkboxes)[position + 1] !== 'undefined'
          ? Object.keys(checkboxes)[position + 1]
          : null;
      var after =
        typeof Object.keys(checkboxes)[position - 1] !== 'undefined'
          ? Object.keys(checkboxes)[position - 1]
          : null;

      if (begin !== null) {
        checkboxes[begin].estado = !e;
      }
      if (after !== null) {
        checkboxes[after].estado = e;
      }
    }

    this.props.onClick(e, detalle);
    var montoCalculo = 0;
    if (e) {
      montoCalculo =
        parseInt(detalle.MONTO_CUOTA) + parseInt(this.state.montoTotal);
    } else {
      montoCalculo =
        parseInt(this.state.montoTotal) - parseInt(detalle.MONTO_CUOTA);
    }
    this.setState({
      montoTotal: montoCalculo,
    });
    this.props.calculaMontoTotal();
  }
  componentWillMount() {
    const { data } = this.props;
    const { moroso, vigente } = data;
    typeof moroso !== 'undefined' &&
      parseInt(moroso.monto) > 0 &&
      moroso.detalle.map((todo) => {
        this.props.setCheckboxes(
          todo.NUMERO_CONTRATO,
          todo.NUMERO_CUOTA,
          todo.CONCEPTO_RECAUDACION,
          this.validadorEstado(todo.CUOTA_MOROSA)
        );
      });
    typeof vigente !== 'undefined' &&
      parseInt(vigente.monto) > 0 &&
      vigente.detalle.map((todo) => {
        this.props.setCheckboxes(
          todo.NUMERO_CONTRATO,
          todo.NUMERO_CUOTA,
          todo.CONCEPTO_RECAUDACION,
          this.validadorEstado(todo.CUOTA_MOROSA)
        );
      });
  }
  validadorEstado(valor) {
    let retorno = true;
    if (parseInt(valor) === 1) {
      if (parseInt(this.state.count) === 0) {
        retorno = false;
        this.state.count = 1;
      } else {
        retorno = true;
      }
    }
    return retorno;
  }
  render() {
    // console.info(this.state.ivas);
    const date = new Date();
    const { data, id, checkboxes } = this.props;
    const { moroso, vigente } = data;
    const { montoTotal } = this.state;
    var show = true;
    const titleColumns = (title, color) => {
      return <p className={`${color} title-col d-none d-md-block`}>{title}</p>;
    };
    return (
      <>
        {show && typeof checkboxes !== 'undefined' && (
          <div className={`container-cartola ${id}`}>
            <h2>
              Resumen de {data.titulo}{' '}
              <span data-toggle="tooltip" data-placement="top" title="">
                <i
                  id="TooltipExample"
                  className="far fa-question-circle"
                  style={{ color: '#627171', fontSize: 15 + 'px' }}
                ></i>
              </span>
            </h2>
            {typeof moroso !== 'undefined' &&
            typeof moroso.detalle !== 'undefined' &&
            moroso.monto > 0 ? (
              moroso.detalle.map((item, index) => (
                <div className="row-cuota box" key={index}>
                  <div className="row">
                    <div className="col-2 col-md-2 order-md-2 text-center">
                      <div className="box-checkbox">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            this.onClick(e.target.checked, item);
                          }}
                          disabled={
                            checkboxes[
                              item.NUMERO_CONTRATO +
                                '-' +
                                item.CONCEPTO_RECAUDACION +
                                '-' +
                                item.NUMERO_CUOTA
                            ].estado
                          }
                        />
                      </div>
                    </div>
                    {date.getFullYear() >= this.state.ivas ? (
                      <>
                        <div className="col-2 col-md-2 text-left">
                          {titleColumns('ESTADO', 'green')}
                          Saldo Anterior{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Cuota: ${item.NUMERO_CUOTA} \rFecha: ${item.FECHA_VENCIMIENTO}`}
                          >
                            <i
                              id="TooltipExample"
                              className="far fa-question-circle"
                              style={{ color: '#627171', fontSize: 15 + 'px' }}
                            ></i>
                          </span>
                        </div>
                        <div className="col-1 col-md-1 text-left d-none d-md-block">
                          {titleColumns('N° CUOTA', 'gray')}
                          {item.NUMERO_CUOTA}
                        </div>
                        <div className="col-1 col-md-1 text-left d-none d-md-block">
                          {titleColumns('VCTO.', 'green')}
                          {item.FECHA_VENCIMIENTO}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('MONTO NETO', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MNT_NETO == null ? 0 : item.MNT_NETO
                          )}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('IVA A BENEFICIO FISCAL (*)', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_IVA == null ? 0 : item.MONTO_IVA
                          )}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('MONTO A PAGAR', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_CUOTA == null ? 0 : item.MONTO_CUOTA
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-5 col-md-3 text-left">
                          {titleColumns('ESTADO', 'green')}
                          Saldo Anterior{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Cuota: ${item.NUMERO_CUOTA} \rFecha: ${item.FECHA_VENCIMIENTO}`}
                          >
                            <i
                              id="TooltipExample"
                              className="far fa-question-circle"
                              style={{ color: '#627171', fontSize: 15 + 'px' }}
                            ></i>
                          </span>
                        </div>
                        <div className="col-4 col-md-2 text-left d-none d-md-block">
                          {titleColumns('N° CUOTA', 'gray')}
                          {item.NUMERO_CUOTA}
                        </div>
                        <div className="col-4 col-md-2 text-left d-none d-md-block">
                          {titleColumns('VENCIMIENTO', 'green')}
                          {item.FECHA_VENCIMIENTO}
                        </div>
                        <div className="col-4 col-md-3 text-center">
                          {titleColumns('MONTO', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_CUOTA
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="row-cuota box">
                <div className="row">
                  <div className="col-12 text-center">
                    No existen cuotas Morosas pendientes
                  </div>
                </div>
              </div>
            )}
            {typeof vigente !== 'undefined' &&
            typeof vigente.detalle !== 'undefined' &&
            vigente.monto > 0 ? (
              vigente.detalle.map((item, index) => (
                <div className="row-cuota box" key={index}>
                  <div className="row">
                    <div className="col-2 col-md-2 order-md-2 text-center">
                      <div className="box-checkbox">
                        {typeof moroso === 'undefined' || moroso.monto <= 0 ? ( // solo vigente
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              this.onClick(e.target.checked, item);
                            }}
                            disabled={false}
                          />
                        ) : (
                          //ambas
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              this.onClick(e.target.checked, item);
                            }}
                            disabled={
                              checkboxes[
                                item.NUMERO_CONTRATO +
                                  '-' +
                                  item.CONCEPTO_RECAUDACION +
                                  '-' +
                                  item.NUMERO_CUOTA
                              ].estado
                            }
                          />
                        )}
                      </div>
                    </div>
                    {date.getFullYear() >= this.state.ivas ? (
                      <>
                        <div className="col-2 col-md-2 text-left">
                          {titleColumns('ESTADO', 'green')}
                          Cuota Vigente{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Cuota: ${item.NUMERO_CUOTA} \rFecha: ${item.FECHA_VENCIMIENTO}`}
                          >
                            <i
                              id="TooltipExample"
                              className="far fa-question-circle"
                              style={{ color: '#627171', fontSize: 15 + 'px' }}
                            ></i>
                          </span>
                        </div>
                        <div className="col-1 col-md-1 text-left d-none d-md-block">
                          {titleColumns('N° CUOTA', 'gray')}
                          {item.NUMERO_CUOTA}
                        </div>
                        <div className="col-1 col-md-1 text-left d-none d-md-block">
                          {titleColumns('VCTO.', 'green')}
                          {item.FECHA_VENCIMIENTO}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('MONTO NETO', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MNT_NETO == null ? 0 : item.MNT_NETO
                          )}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('IVA A BENEFICIO FISCAL (*)', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_IVA == null ? 0 : item.MONTO_IVA
                          )}
                        </div>
                        <div className="col-1 col-md-2 text-center">
                          {titleColumns('MONTO A PAGAR', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_CUOTA == null ? 0 : item.MONTO_CUOTA
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-2 col-md-2 text-left">
                          {titleColumns('ESTADO', 'green')}
                          Cuota Vigente{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Cuota: ${item.NUMERO_CUOTA} \rFecha: ${item.FECHA_VENCIMIENTO}`}
                          >
                            <i
                              id="TooltipExample"
                              className="far fa-question-circle"
                              style={{ color: '#627171', fontSize: 15 + 'px' }}
                            ></i>
                          </span>
                        </div>
                        <div className="col-4 col-md-2 text-left d-none d-md-block">
                          {titleColumns('N° CUOTA', 'gray')}
                          {item.NUMERO_CUOTA}
                        </div>
                        <div className="col-4 col-md-2 text-left d-none d-md-block">
                          {titleColumns('VENCIMIENTO', 'green')}
                          {item.FECHA_VENCIMIENTO}
                        </div>
                        <div className="col-4 col-md-3 text-center">
                          {titleColumns('MONTO', 'blue')}$
                          {new Intl.NumberFormat('de-DE').format(
                            item.MONTO_CUOTA
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="row-cuota box">
                <div className="row">
                  <div className="col-12 text-center">
                    No existen cuotas Vigentes pendientes
                  </div>
                </div>
              </div>
            )}
            <div className="row row-subtotal">
              <div className="col-6">
                <p>Subtotal</p>
              </div>
              <div className="col-6 offset-md-3 col-md-3 text-right">
                <p>${new Intl.NumberFormat('de-DE').format(montoTotal)}</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default TableCuota;
